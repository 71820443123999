import React from "react";
import GraphStore from "./GraphStore";
import LoginStore from "./LoginStore";
import CompetitorStore from "./CompetitorStore";

export class RootStore {
  public loginStore: LoginStore;
  public graphStore: GraphStore;
  public competitorStore: CompetitorStore;

  constructor() {
    this.loginStore = new LoginStore(this);
    this.graphStore = new GraphStore(this);
    this.competitorStore = new CompetitorStore(this);
  }
}

const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);

export default RootStore;
