import React, { useContext, useState, useEffect, useCallback } from "react";
import { OjamuContext } from "../index.js";
import CryptoIcon from "./Icons/CryptoIcon";
import channelIcon from "../styles/navIcons/channel.svg";
import communitiesIcon from "../styles/navIcons/communities.svg";
import engagementIcon from "../styles/navIcons/engagement.svg";
import contentIcon from "../styles/navIcons/content.svg";
import dailyVolumeIcon from "../styles/navIcons/dailyVolume.svg";
import followersIcon from "../styles/navIcons/followers.svg";
import growthRateIcon from "../styles/navIcons/growthRate.svg";
import holdersIcon from "../styles/navIcons/holders.svg";
import rightarrow from "../styles/navIcons/right-arrow.svg";
import OjamuLogo from "./Logo";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useStores } from "../store/RootStore";
import { NavLink } from "react-router-dom";
import { Observer } from "mobx-react";
import padlockIcon from "../styles/padlock-icon.svg";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navigation = (props) => {
  const [competitors, setCompetitors] = useState([]);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const { value, setValue } = useContext(OjamuContext);
  const { competitorStore, loginStore } = useStores();
  const [ menuItems, setMenuItems] = useState([]);
  const [ showSelectorList, setShowSelectorList] = useState(false);

  const menuItem = [
    {id: 13, name: "Bitcoin", industry: "Layer 1"},
    {id: 6, name: "BSC", industry: "Layer 1"},
    {id: 7, name: "Cardano", industry: "Layer 1"},
    {id: 2, name: "Ethereum", industry: "Layer 1"},
    {id: 3, name: "Polkadot", industry: "Layer 1"},
    {id: 1, name: "Solana", industry: "Layer 1"},
    {id: 9, name: "Fantom", industry: "Layer 1"},
    {id: 8, name: "Polygon", industry: "Layer 1"},
    {id: 10, name: "Chainlink", industry: "Data feed"},
    {id: 19, name: 'Unmarshal', industry: 'Data feed'},
    {id: 11, name: "Decentraland", industry: "Blockchain game"},
    {id: 16, name: "The Sandbox", industry: "Blockchain game"},
  ]


  const fetchData = useCallback(async () => {
    const fetchedCompetitors = await competitorStore.getCompetitors();

    if (!fetchedCompetitors) {
      return [];
    }
    const sortOrder = ['Layer 1','Metaverse','Gaming', 'DeFi', 'Scaling', 'Meme', 'Ojamu'];

    let sortedCompetitors = fetchedCompetitors.sort((a, b) => {
      if (a.industry === b.industry) {
        return b.name - a.name;
     }
      return a.industry > b.industry ? 1 : -1
    })

    return sortedCompetitors.sort((a, b) => sortOrder.indexOf(a.industry) - sortOrder.indexOf(b.industry))
  }, []);

  const changeCompetitor = (e, itemID) => {
    e.preventDefault();
    setShowSelectorList(false);
    setValue(itemID);
  }

  useEffect(() => {
    if (competitors.length === 0) {
      const items = [];
      fetchData().then( (competitors) => {
        setCompetitors(competitors);
        let industries = [];
        let showIndustry = false;
        // competitors.forEach((item) => {
          // if(industries.indexOf(item.industry) === -1) {
          //   industries.push(item.industry);
          //   showIndustry = true
          // } else {
          //   showIndustry = false
          // }

          menuItem.forEach((item) => {
            if(industries.indexOf(item.industry) === -1) {
              industries.push(item.industry);
              showIndustry = true
            } else {
              showIndustry = false
            }
            items.push((<li key={item.id}>
              {showIndustry && (
                <span className="Selector__List__Industry">{item.industry}</span>
              )}
              <span className="Selector__List__Item d-flex align-items-center" onClick={(e) => changeCompetitor(e, item.id)}>
                <CryptoIcon id={item.id} name={item.name} />
                  {item.name}
              </span>
            </li>))
          });
        
        setMenuItems(items)
      });
    }
  }, [competitors, fetchData]);

  const toggleSelectorList = () => {
    setShowSelectorList(!showSelectorList);
  }

  const toggleClass = () => {
    setNavIsOpen(!navIsOpen);
    document.body.classList.add("MobileNavigation--open");
  };

  const logOut = () => {
    loginStore.logOut();
  };

  return (
    <>
      <nav className={navIsOpen ? "Navigation--open Navigation d-flex" : "Navigation d-flex"}>
        <div className="row w-100">
          <div className="col-6 col-sm-12 px-3 text-sm-center mb-3 mb-sm-0">
            <NavLink className="nav-link" to="/engagement">
              <OjamuLogo />
            </NavLink>
          </div>

          <div className="col-6 d-flex justify-content-end d-sm-none pt-2 px-0">
            <button
              className={
                navIsOpen ? "d-sm-none Navigation__Burger Navigation__Burger--open" : "d-sm-none Navigation__Burger"
              }
              onClick={toggleClass}
            >
              <div>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>

        </div>
        {competitors.length > 0 && (
          <div className="p-2 p-sm-3 pb-0 d-flex flex-column">
            <span className="pb-2 d-none d-sm-block">Select Project:</span>
            <div className="Selector">
              <span className="Selector__Label" onClick={toggleSelectorList}>
                <i className={`${showSelectorList ? "show" : ""}`}></i>
                <CryptoIcon id={value} className="Selector__Label__Icon" name={value} />
                {competitors ? competitors.find( (c) => c.id === parseInt(value)).name : 'Select Project...'}
              </span>
              <ul className={`Selector__List ${showSelectorList ? "Selector__List--show" : ""}`}>
                {menuItems}
              </ul>
            </div>

            <hr className="mt-0 d-none d-sm-block" />
          </div>
        )}

        <ul className="nav flex-column">

          <li className="nav-item">
            <Observer>
              {() => {
                console.log("test", loginStore.loginIsSuccessful);
                return (
                  <NavLink className={"nav-link " + (loginStore.isAuthenticated() ? "" : "nav-link-locked")} end to="/engagement">
                    <div>
                      <img src={engagementIcon} className="nav-link-icon" alt="Engagement" height="16" />
                      Engagement
                    </div>

                    {loginStore.isAuthenticated() ? (
                      <i></i>
                    ) : (
                      <img src={padlockIcon} className={"nav-link-padlock"} alt={"Log In required"} />
                    )}
                  </NavLink>
                )
              }}
            </Observer>
          </li>

          <li className="nav-item">
            <Observer>
              {() => {
                console.log("test", loginStore.loginIsSuccessful);
                return (
                  <NavLink className={"nav-link " + (loginStore.isAuthenticated() ? "" : "nav-link-locked")} end to="/followers">
                    <div>
                      <img src={followersIcon} className="nav-link-icon" alt="Followers" height="16" />
                      Followers
                    </div>

                    {loginStore.isAuthenticated() ? (
                      <i></i>
                    ) : (
                      <img src={padlockIcon} className={"nav-link-padlock"} alt={"Log In required"} />
                    )}
                  </NavLink>
                )
              }}
            </Observer>
          </li>

          <li className="nav-item">
            <Observer>
              {() => {
                console.log("test", loginStore.loginIsSuccessful);
                return (
                  <NavLink className={"nav-link " + (loginStore.isAuthenticated() ? "" : "nav-link-locked")} end to="/holders">
                    <div>
                      <img src={holdersIcon} className="nav-link-icon" alt="Holders" height="16" />
                      Holders
                    </div>

                    {loginStore.isAuthenticated() ? (
                      <i></i>
                    ) : (
                      <img src={padlockIcon} className={"nav-link-padlock"} alt={"Log In required"} />
                    )}
                  </NavLink>
                )
              }}
            </Observer>
          </li>

          <li className="nav-item opacity-25">
            <NavLink className="nav-link " end to="/">
              <div>
                <img src={contentIcon} className="nav-link-icon" alt="Egagement" height="16" />
                Content
              </div>
              <i></i>
            </NavLink>
          </li>
          <li className="nav-item opacity-25">
            <NavLink className="nav-link " end to="/">
              <div>
                <img src={channelIcon} className="nav-link-icon" alt="Egagement" height="16" />
                Channel
              </div>
              <i></i>
            </NavLink>
          </li>
          <li className="nav-item opacity-25">
            <NavLink className="nav-link " end to="/">
              <div>
                <img src={dailyVolumeIcon} className="nav-link-icon" alt="Egagement" height="16" />
                Daily Volume
              </div>
              <i></i>
            </NavLink>
          </li>
          <li className="nav-item opacity-25">
            <NavLink className="nav-link " end to="/">
              <div>
                <img src={communitiesIcon} className="nav-link-icon" alt="Egagement" height="16" />
                Communities
              </div>
              <i></i>
            </NavLink>
          </li>
          <li className="nav-item opacity-25">
            <NavLink className="nav-link " end to="/">
              <div>
                <img src={growthRateIcon} className="nav-link-icon" alt="Egagement" height="16" />
                Growth Rate
              </div>
              <i></i>
            </NavLink>
          </li>

        </ul>

        <Observer>
          {() => {
            console.log("test", loginStore.isAuthenticated(), loginStore.loginIsSuccessful);
            if (loginStore.isAuthenticated()) {
              return (
                <div className="mt-auto d-none d-sm-flex justify-content-center py-4">
                  <a className="SignOut" href="/" onClick={logOut}>
                    <FontAwesomeIcon className="SignOut__Icon" icon={faSignOutAlt} />
                    <span>Sign Out</span>
                  </a>
                </div>
              )
            } else {
              return (
                <></>
              )
            }
          }}
        </Observer>

      </nav>
    </>
  );
};
export default Navigation;
