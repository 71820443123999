import React, { useRef, forwardRef, useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import ChainLinkLogo from "../../styles/chainlink-logo.svg";
import OjamuLogo from "../../styles/ojamu-logo-w.png";
import CryptoIcon from "../Icons/CryptoIcon";
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from "moment";
import Hammer from "hammerjs";

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  CategoryScale,
} from "chart.js";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, TimeScale, zoomPlugin);

const Graph = forwardRef((props, ref) => {
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [clickedTooltip, setClickedTooltip] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1200);

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.classList.add("Graph__Tooltip");

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
    const content = document.createElement("div");
    const bubbleContent = props.bubbleContent;

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      document.body.style.cursor = "default";

      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);
      const headline = document.createElement("strong");

      titleLines.forEach((title) => {
        const date = moment(title).format("MMM DD, YYYY");
        const div = document.createElement("div");
        const text = document.createTextNode(date);
        div.appendChild(text);

        headline.appendChild(div);
      });

      const div = document.createElement("div");

      const price = Number(bodyLines[0][0].replace("leftYAxis: ", "").replace(",", ".")).toFixed(2);
      const cf = bodyLines[1][0].replace("rightYAxis: ", "");

      const link = document.createElement("div");
      link.classList.add("Graph__Tooltip__Footer");

      link.innerHTML = "<strong>Click <i class='Circle'></i> To Learn More</strong>";

      div.innerHTML =
        "<div>" + props.leftYAxisTitle + ": <span class='color-lila'>$" +
        price +
        "</span> <span class='px-2'>x</span> "+ props.rightYAxisTitle + ": <span class='color-purple'>" +
        cf +
        "</span></div>";

      const tooltipRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tooltipRoot.firstChild) {
        tooltipRoot.firstChild.remove();
      }

      // Add new children
      tooltipRoot.appendChild(headline);
      tooltipRoot.appendChild(div);
      tooltipRoot.appendChild(link);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";

    document.body.style.cursor = "pointer";
  };

  const handleLangChange = (index) => {
    props.onSelectBubble(index);
  };

  const createGradient = (ctx, area, label) => {
    const colorSchemes = {
      rightYAxis: {
        start: "#FF71BB",
        mid: "#E10C7C",
        end: "#E10C7C",
      },
      leftYAxis: {
        start: "#C09FEF",
        mid: "#C09FEF",
        end: "#7950b6",
      },
    };

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, colorSchemes[label].start);
    gradient.addColorStop(0.5, colorSchemes[label].mid);
    gradient.addColorStop(1, colorSchemes[label].end);

    return gradient;
  };

  const options = (mobileView) => {
    return {
      color: "#fff",
      maintainAspectRatio: false,
      animation: false,
      onClick: function (evt, element) {
        if (element.length > 0) {
          handleLangChange(element[0].index);
         // setClickedTooltip(element[0].index);
        }
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        legend: {
          position: "bottom",
        },
        tooltip: {
          enabled: false,
          position: "nearest",
          external: !isMobileView ? externalTooltipHandler.bind(mobileView) : null,
        },
        zoom: {
          limits: {
            x: {
              min: mobileView ? moment(props.data.labels[0]).unix() * 1000 : null,
              max: mobileView ? moment(props.data.labels.slice(-1).pop()).unix() * 1000 : null,
            }
          },
          pan: {
            enabled: window.innerWidth <= 1200,
            // overScaleMode: 'x',
            mode: 'x',
          },
        }
      },

      scales: {
        y: {
          id: "A",
          type: "linear",
          min: mobileView ? Math.floor(Math.min(...props.data.datasets[0].data) / 100) * 100 : null,
          max: mobileView ? Math.ceil(Math.max(...props.data.datasets[0].data) / 100) * 100 : null,
          display: true,
          position: "left",
          ticks: {
            color: "#fff",
            callback: function (value, index, values) {
              return "$" + Number(value).toFixed(2);
            },
          },
          font: {
            color: "#fff",
          },
          grid: {
            color: "#414141",
          },
        },
        y1: {
          id: "B",
          type: "linear",
          min: mobileView ? Math.floor(Math.min(...props.data.datasets[1].data) / 1000) * 1000 : null,
          max: mobileView ? Math.ceil(Math.max(...props.data.datasets[1].data) / 1000) * 1000 : null,
          display: true,
          position: "right",
          ticks: {
            color: "#fff",

            callback: function (value, index, values) {
              return value.toLocaleString("en");
            },
          },
          grid: {
            drawOnChartArea: false,
          },
        },
        xAxis: {
          type: "time",
          display: true,
          min: mobileView ? moment(props.data.labels[0]).unix() * 1000 : null,
          max: mobileView ? moment(props.data.labels[3]).unix() * 1000 : null,
          ticks: {
            callback: function (value, index, values) {
              if(!mobileView) {
                switch (props.data.range) {
                  case 30:
                    return index % 7 === 0 ? value : "";
                  default:
                    return value;
                }
              } else {
                return moment(value).format('MM/DD');
              }
            },
            color: "white",
            maxRotation: 0,
            minRotation: 0,
            padding: 20,
          },
          time: {
            isoWeekday: true,
            unit: "day",
            displayFormats: {
              day: "MM/DD/Y",
            },
          },
          grid: {
            drawOnChartArea: false,
          },
        },
      }
    }
  };

  useEffect(() => {
    const chart = chartRef.current;

    setIsMobileView(window.innerWidth <= 1200);
    window.addEventListener('resize', () => {
      setIsMobileView(window.innerWidth <= 1200);
    });

    if (!chart) {
      return;
    }

    const chartData = {
      ...props.data,
      datasets: props.data.datasets.map((dataset) => ({
        ...dataset,
        borderColor: createGradient(chart.ctx, chart.chartArea, dataset.label),
        borderWidth: isMobileView ? 3 : 5,
        pointRadius: isMobileView ? 3 : 0,
      })),
    };


    setChartData(chartData);

  }, [props.data]);


  const chartRef = useRef(null);
  return (
    <>
      <span className="Graph__Label d-none d-sm-inline-block">
        <i>{props.leftYAxisTitle}</i>
      </span>

      <div className="col">
        <div className="Graph__Legends justify-content-center justify-content-sm-between">
          <strong className="Graph__Legends__Title d-none d-sm-flex">
            {props.graphTitle}
          </strong>

          <div>
            <span className="px-2 d-none d-sm-inline-block">Legend:</span>
            <span className="Graph__Legends__Legend"> {props.leftYAxisTitle} </span>
            <span className="Graph__Legends__Legend"> {props.rightYAxisTitle} </span>
          </div>
        </div>
        <div className="h-100 Graph--bottom--p">
          {props.data.datasets && <Line className="Graph__Canva" data={chartData} options={options(isMobileView)} ref={chartRef} />}
          <span className="Graph__Footer">
            <span>
              <img src={OjamuLogo} className="OjamuLogo mx-2" alt="Ojamu Logo" />
            </span>

            <span>
              <small className="d-none d-sm-inline-block">Price Data Powered By</small>
              <img src={ChainLinkLogo} className="ChainlinkLogo mx-2" alt="Chainlink Logo" />
            </span>
          </span>
        </div>
      </div>

      <span className="Graph__Label d-none d-sm-inline-block">
        <i>{props.rightYAxisTitle}</i>
      </span>
    </>
  );
});

export default Graph;
