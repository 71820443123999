import API, { BASE_URL } from "../utils/ApiUtil";
import JwtProvider from "../auth/JwtProvider";

export default class MarketingApi {
  public static getPriceTwitterEngagementMetrics = (
    request: PriceTwitterEngagementRequest
  ): Promise<PriceTwitterEngagementResponse> => {
    return API.get(
      BASE_URL +
        `insight/priceXEngagement?competitorId=${request.competitorId}&startDate=${request.startDate}&endDate=${request.endDate}`,
      {
        headers: { "x-access-token": this.getJwt() },
      }
    );
  };

  public static getPriceXFollowers = (
    request: PriceXFollowersRequest
  ): Promise<PriceXFollowersResponse> => {
    return API.get(
      BASE_URL +
      `insight/priceXFollowers?competitorId=${request.competitorId}&startDate=${request.startDate}&endDate=${request.endDate}`,
      {
        headers: { "x-access-token": this.getJwt() },
      }
    );
  };

  public static getPriceXTokenHolders = (
    request: PriceXTokenHoldersRequest
  ): Promise<PriceXTokenHoldersResponse> => {
    return API.get(
      BASE_URL +
      `insight/priceXTokenHolders?competitorId=${request.competitorId}&startDate=${request.startDate}&endDate=${request.endDate}`,
      {
        headers: { "x-access-token": this.getJwt() },
      }
    );
  };

  public static getCompetitors = (): Promise<CompetitorResponse> => {
    return API.get(BASE_URL + "insight/public/competitor/competitor-details");
  };

  public static logInWithMetaMaskByBalance = (credentials: MetaMaskCredentials): Promise<LoginResponse> => {
    return API.post(BASE_URL + "marketing/login/metamask-insights", credentials);
  };

  private static getJwt() {
    return JwtProvider.getJwt();
  }
}
