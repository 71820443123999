import React, { useState, useEffect, useCallback, createRef } from "react";
import Graph from "./Graph";
import { useScreenshot, createFileName } from "use-react-screenshot";
import Loading from "../Loading";
import moment from "moment";
import circleIcon from "../../styles/circleIcon.svg";
import infoIcon from "../../styles/info-icon.svg";
import padlockIcon from "../../styles/padlock-icon.svg";
import closeIcon from "../../styles/close-icon.svg";
import CryptoIcon from "../Icons/CryptoIcon";
import SocialmediaIcon from "../Icons/SocialmediaIcon";
import { useStores } from "../../store/RootStore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GraphTypeEngagement, GraphTypeFollowers, GraphTypeTokenHolders } from "../../store/GraphStore";
import LoginForm from "../LoginForm";
import { Observer } from "mobx-react";
import { calculateMetaMaskCredentials } from "src/auth/MetaMaskProvider";
import { graphDataMock } from "../../utils/TestMocks";

const GraphContainer = (props) => {
  const [data, setData] = useState([]);
  const [graphType, setGraphType] = useState("");
  const [rightYAxisChange, setRightYAxisChange] = useState({});
  const [leftYAxisChange, setLeftYAxisChange] = useState({});
  const [bubbleContent, setBubbleContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showGraphLightbox, setShowGraphLightbox] = useState(false);
  const [showInfoLightbox, setShowInfoLightbox] = useState(false);
  const { graphStore, loginStore, competitorStore } = useStores();
  const ref2 = createRef(null);
  const [image, takeScreenshot] = useScreenshot();

  const getImage = () => {
    takeScreenshot(ref2.current).then((file) => download(file));
    return image;
  };

  const download = (image, { name = data.name + "-" + getLeftYAxisTitle() + "_x_" + getRightYAxisTitle(), extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  function definedDaysRange(selected) {
    fetchAdditionalData(parseInt(selected.target.value)).then();
  }

  const fetchCompetitors = useCallback(async () => {
      return await competitorStore.getCompetitors();
    },
    [competitorStore]
  );

  const setMockData = (range) => {
    fetchCompetitors().then( (competitors) => {
      const competitor = competitors.find((competitor) => competitor.id === props.companyID);
      let mockData = graphDataMock;
      mockData.competitorId = props.companyID;
      mockData.competitorLabel = competitor.name;
      setData(getNormalizedGraphData(mockData, range));
      calculateMetricChanges(mockData);
    });
  };

  const fetchAdditionalData = useCallback(async (range) => {
      setIsLoading(true);

      if (!loginStore.isAuthenticated()) {
        setMockData(range);

      } else {

        graphStore
          .getGraphDataByRange(range, props.companyID, props.graphType)
          .then((graphData) => {

            if (graphData) {
              setData(getNormalizedGraphData(graphData, range));
              calculateMetricChanges(graphData);
            }
          })
          .catch((e) => {
            console.log("error: ", e);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

    },
    [props.companyID, graphStore, props.graphType]
  );

  useEffect(() => {
    const competitorId = props.companyID;
    const propsGraphType = props.graphType;
    const setGraphData = (range, graphData) => {
      if (graphData) {
        const normalizedGraphData = getNormalizedGraphData(graphData, range);
        setData(normalizedGraphData);
        calculateMetricChanges(graphData);
      }
      setIsLoading(false);
    };

    const fetchData = async (range) => {
      setIsLoading(true);
      if (!loginStore.isAuthenticated()) {
        setMockData(range);
        setIsLoading(false);

      } else {
        const graphData = await graphStore.getGraphDataByRange(range, competitorId, propsGraphType);
        setGraphData(range, graphData);
      }
    };

    if (data.length === 0 || data.id !== competitorId || graphType !== propsGraphType) {
      fetchData(30).then();
    }
  }, [data, props.companyID, graphStore, setData, props.graphType]);

  useEffect(() => {
    if (props.graphType !== graphType) {
      setGraphType(props.graphType);
    }
  }, [props.graphType]);

  const getNormalizedGraphData = (graphData, range) => {
    const circleImage = new Image();
    circleImage.src = circleIcon;

    return {
      id: graphData.competitorId,
      name: graphData.competitorLabel,
      labels: graphData.dataPoints.map((graphDataPoint) => graphDataPoint.date),
      range,
      datasets: [
        {
          yAxisID: "y",
          label: "leftYAxis",
          data: graphData.dataPoints.map((graphDataPoint) => graphDataPoint.leftYAxis),
          borderColor: "#7950b6",
          bezierCurve: true,
          tension: 0.125,
          pointStyle: circleImage,
        },
        {
          yAxisID: "y1",
          label: "rightYAxis",
          pointStyle: circleImage,
          data: graphData.dataPoints.map((graphDataPoint) => graphDataPoint.rightYAxis),
          borderColor: "#e10c7c",
          tension: 0.125,
        },
      ],
    };
  };

  function calculateLeftYAxisChange(firstGraphDataElement, lastGraphDataElement) {
    const initialPrice = firstGraphDataElement.leftYAxis;
    const finalPrice = lastGraphDataElement.leftYAxis;
    const { polarization, percentage } = calculateChanges(initialPrice, finalPrice);

    const price = {
      polarization: polarization,
      percentageChange: percentage,
    };

    setLeftYAxisChange(price);
  }

  function calculateChanges(initialValue, lastValue) {
    const change = lastValue - initialValue;
    const polarization = change > 0 ? "up" : "down";
    let percentage = Math.abs(Math.round((change / initialValue) * 100));
    if (initialValue === 0) {
      percentage = change;
    }

    return { polarization: polarization, percentage: percentage };
  }

  function calculateRightYAxisChange(firstGraphDataElement, lastGraphDataElement) {
    const initialEngagement = firstGraphDataElement.rightYAxis;
    const lastEngagement = lastGraphDataElement.rightYAxis;
    const { polarization, percentage } = calculateChanges(initialEngagement, lastEngagement);

    const engagement = {
      polarization: polarization,
      percentageChange: percentage,
    };

    setRightYAxisChange(engagement);
  }

  const calculateMetricChanges = (graphData) => {
    const firstGraphDataElement = graphData.dataPoints[0];
    const lastGraphDataElement = graphData.dataPoints[graphData.dataPoints.length - 1];
    calculateLeftYAxisChange(firstGraphDataElement, lastGraphDataElement);
    calculateRightYAxisChange(firstGraphDataElement, lastGraphDataElement);
  };

  const handleSelect = (e) => {
    const d1 = data.datasets[0].data[e].toFixed(2);
    const d2 = data.datasets[1].data[e];
    const date = moment(data.labels[e]).format("MMM DD, YYYY");
    const range = data.range;
    const leftYAxisPolarization = leftYAxisChange.polarization;
    const leftYAxisPercentage = leftYAxisChange.percentageChange;
    const rightYAxisPolarization = rightYAxisChange.polarization;
    const rightYAxisPercentage = rightYAxisChange.percentageChange;

    setBubbleContent({});
    setBubbleContent({
      date,
      price: d1,
      cf: d2,
      range: range,
      leftYAxisPolarization: leftYAxisPolarization,
      leftYAxisPercentage: leftYAxisPercentage,
      rightYAxisPolarization: rightYAxisPolarization,
      rightYAxisPercentage: rightYAxisPercentage,
    });
    setShowGraphLightbox(true);
  };

  const hideLightboxes = () => {
    setShowGraphLightbox(false);
    setShowInfoLightbox(false);
  }

  const getInfoLightboxBody = () => {
    let introText = "";
    let leftYAxisText = "";
    let rightYAxisText = "";

    if (graphType === GraphTypeEngagement) {
      introText = "This graph displays the correlation between a selected company's market price and the amount of community engagement on their official Twitter handle at defined points in time.";
      leftYAxisText = "Price data is displayed via the official Chainlink price data feed integration.";
      rightYAxisText = "Engagement is defined by likes, retweets and replies on Twitter.";

    } else if (graphType === GraphTypeFollowers) {
      introText = "This graph displays the correlation between a selected company's market price and the amount of followers on their official Twitter handle at defined points in time.";
      leftYAxisText = "Price data is displayed via the official Chainlink price data feed integration.";
      rightYAxisText = "Followers is defined by followers on Twitter.";
    } else if (graphType === GraphTypeTokenHolders) {
      introText = "This graph displays the correlation between a selected company's market price and the amount of token holders.";
      leftYAxisText = "Price data is displayed via the official Chainlink price data feed integration.";
      rightYAxisText = "Holders is defined by ERC-20 token holders in Ethernum chain.";
    }

    return (
      <div>
        <p>
          {introText}
        </p>
        <p>{leftYAxisText}</p>
        <p>{rightYAxisText}</p>
      </div>
    );
  }

  const getGraphLightboxBody = () => {
    let leftYAxisText = "";
    let rightYAxisText = "";

    if (graphType === GraphTypeEngagement) {
      leftYAxisText = "price";
      rightYAxisText = "engagement";

    } else if (graphType === GraphTypeFollowers) {
      leftYAxisText = "price";
      rightYAxisText = "followers";
    } else if (graphType === GraphTypeTokenHolders) {
      leftYAxisText = "price";
      rightYAxisText = "holders";
    }

    return (
      <div>
        <p>
          {data.name} {leftYAxisText} was <strong>${bubbleContent.price}</strong> with a total Twitter {rightYAxisText} of <strong>{bubbleContent.cf}</strong> on {bubbleContent.date}.
        </p>
        <p>
          {data.name} {leftYAxisText} is {bubbleContent.leftYAxisPolarization} by <strong>{bubbleContent.leftYAxisPercentage}%</strong> in the last {bubbleContent.range} day period.
        </p>
        <p>
          {data.name} {rightYAxisText} is {bubbleContent.rightYAxisPolarization} by <strong>{bubbleContent.rightYAxisPercentage}% </strong> in the last {bubbleContent.range} day period.
        </p>
      </div>
    )
  }

  const getLeftYAxisTitle = () => {
    if (graphType === GraphTypeEngagement) {
      return "Price";
    } else if (graphType === GraphTypeFollowers) {
      return "Price";
    } else if (graphType === GraphTypeTokenHolders) {
      return "Price";
    }
    return "";
  }

  const getRightYAxisTitle = () => {
    if (graphType === GraphTypeEngagement) {
      return "Engagement";
    } else if (graphType === GraphTypeFollowers) {
      return "Followers";
    } else if (graphType === GraphTypeTokenHolders) {
      return "Holders";
    }
    return "";
  }

  const getGraphTitle = () => {
    return (
      <div>
        <CryptoIcon id={data.id} name={data.name} /> {data.name} - {getLeftYAxisTitle()} x {getRightYAxisTitle()}
      </div>
    );
  }

  const renderTooltip = props => (
    <Tooltip {...props}>Coming Soon</Tooltip>
  );

  const loginMetaMaskUser = async () => {
    const metaMaskCredentials = await calculateMetaMaskCredentials();
    await loginStore.loginWithMetaMask(metaMaskCredentials);
    setData([]);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <div className="row">
            <div className="col col-12">
              <div className="card p-0">
                <div className="Graph__Container__Header">
                  <div className="row p-0 pt-3 pt-sm-0">
                    <div className="col col-12 d-none d-sm-inline-block">
                      <div className="border-bottom border-light d-flex justify-content-between align-items-center m-3 pb-3">
                        <div className="d-flex">
                          <strong>
                            {getGraphTitle()}
                          </strong>
                          <span className="Graph__Container__Header__Description">
                            <a href="#" className="Graph__Container__Header--link" onClick={() => setShowInfoLightbox(true)}>
                              See Metric Details
                              <img className="mx-2" src={infoIcon} />
                            </a>
                          </span>
                        </div>
                        {!loginStore.isAuthenticated() && (
                          <div>
                            <span>Log In required</span>
                            <img className="mx-2" src={padlockIcon} height="16" />
                          </div>
                        )}
                        {loginStore.isAuthenticated() &&  (
                          <div>
                            <button className="btn btn-secondary btn--snapshot" onClick={getImage}>
                              Take Snapshot:
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="29.249"
                                height="24.203"
                                viewBox="0 0 29.249 24.203"
                              >
                                <g
                                  id="Icon_feather-camera"
                                  data-name="Icon feather-camera"
                                  transform="translate(-0.75 -3.75)"
                                >
                                  <path
                                    id="Path_1413"
                                    data-name="Path 1413"
                                    d="M29.249,24.681A2.523,2.523,0,0,1,26.726,27.2H4.023A2.523,2.523,0,0,1,1.5,24.681V10.806A2.523,2.523,0,0,1,4.023,8.284H9.068L11.59,4.5h7.568l2.523,3.784h5.045a2.523,2.523,0,0,1,2.523,2.523Z"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Path_1414"
                                    data-name="Path 1414"
                                    d="M22.09,18.545A5.045,5.045,0,1,1,17.045,13.5a5.045,5.045,0,0,1,5.045,5.045Z"
                                    transform="translate(-1.671 -1.432)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col col-12 ">
                      <div className="ResolutionPicker p-3 pt-0">
                        <label className="GraphContainer__Label  d-none d-sm-block">Resolution Period:</label>

                        <div role="group" aria-label="Basic radio toggle button group">
                          <input
                            type="radio"
                            className="btn-check"
                            name="resolution"
                            id="resolution3"
                            value="7"
                            onChange={definedDaysRange.bind(this)}
                            checked={data.range === 7}
                          />
                          <label className="btn btn-radio" htmlFor="resolution3">
                            7D
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="resolution"
                            id="resolution2"
                            value="14"
                            onChange={definedDaysRange.bind(this)}
                            checked={data.range === 14}
                          />
                          <label className="btn btn-radio" htmlFor="resolution2">
                            14D
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="resolution"
                            id="resolution1"
                            value="30"
                            onChange={definedDaysRange.bind(this)}
                            checked={data.range === 30}
                          />
                          <label className="btn btn-radio" htmlFor="resolution1">
                            30D
                          </label>
                        </div>

                        <div className="ml-auto">
                          <div role="group" aria-label="Basic radio toggle button group">
                            <label className="GraphContainer__Label  d-none d-sm-inline-block">Social Media Platform:</label>
                            <input
                              type="radio"
                              className="btn-check"
                              name="socialmediachoice"
                              id="sm_twitter"
                              value="twitter"
                              onChange={() => true}
                              checked
                            />
                            <label className="btn btn-radio mx-2" htmlFor="sm_twitter">
                              <SocialmediaIcon id={'twitter'} name={'twitter'} /><span className="pl-2 d-none d-sm-inline">Twitter</span>
                            </label>

                            <input
                              type="radio"
                              className="btn-check btn-check--disabled"
                              name="socialmediachoice"
                              id="sm_discord"
                              value="discord"
                            />
                            <OverlayTrigger placement="top" overlay={renderTooltip}>
                              <label className="btn btn-radio mx-2" htmlFor="sm_discord">
                                <SocialmediaIcon id={'discord'} name={'telegram'} />
                              </label>
                            </OverlayTrigger>
                              <input
                                type="radio"
                                className="btn-check btn-check--disabled"
                                name="socialmediachoice"
                                id="sm_telegram"
                                value="telegram"
                              />
                              <OverlayTrigger placement="top" overlay={renderTooltip}>
                                <label className="btn btn-radio mx-0" htmlFor="sm_telegram">
                                  <SocialmediaIcon id={'telegram'} name={'telegram'} />
                                </label>
                              </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className={"Graph " + (!loginStore.isAuthenticated() ? "Graph__Blur" : "")} ref={ref2}>
                    {data.labels && (
                      <Graph
                        data={data}
                        onSelectBubble={handleSelect}
                        bubbleContent={bubbleContent}
                        graphType={props.graphType}
                        graphTitle={getGraphTitle()}
                        leftYAxisTitle={getLeftYAxisTitle()}
                        rightYAxisTitle={getRightYAxisTitle()}
                      />
                    )}
                  </div>
                  {!loginStore.isAuthenticated() && (
                    <div className={"Graph__Login"}>
                      <Observer>
                        {() => (
                          <LoginForm onMetaMaskSubmit={loginMetaMaskUser} />
                        )}
                      </Observer>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {showGraphLightbox &&
            <div className="Lightbox animate--slideUp" onClick={hideLightboxes}>
              {(bubbleContent && bubbleContent.price) &&
                <div className="Lightbox__Content pb-5">

                  <div className="pb-4 d-flex justify-content-between bg--black Lightbox__Header">
                    <div className="d-flex align-items-center w-100">
                      <i className='Circle'></i>
                      <span className="d-flex px-2">
                        Highlighted Detail
                      </span>
                      <span className="px-1">|</span>
                      <strong className='color-purple px-2'>{bubbleContent.date}</strong>
                    </div>

                    <button className="Lightbox__Close" onClick={hideLightboxes}>
                      <img className="mx-2" src={closeIcon} />
                    </button>
                  </div>

                  <section className="Lightbox__Body">
                    {getGraphLightboxBody()}
                  </section>

                </div>
              }
            </div>
          }

          {showInfoLightbox &&
            <div className="Lightbox animate--slideUp" onClick={hideLightboxes}>
                <div className="Lightbox__Content pb-4">

                  <div className="pb-4 d-flex justify-content-between Lightbox__Header">
                    <div className="d-flex align-items-center w-100">
                      <h6 className="d-flex px-2 py-0 my-0">
                        Graph Details
                      </h6>
                    </div>
                    <button className="Lightbox__Close" onClick={hideLightboxes}>
                      <img className="mx-2" src={closeIcon} />
                    </button>
                  </div>

                  <section className="Lightbox__Body">
                    {getInfoLightboxBody()}
                  </section>
                </div>
            </div>
          }

        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default GraphContainer;
