import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";

export interface CompetitorInterface {
  fetchCompetitorsIsErroneous: boolean;
  competitorsResponse: CompetitorResponse;
  projects: Project[];
}

class CompetitorStore implements CompetitorInterface {
  public fetchCompetitorsIsErroneous: boolean = false;
  public competitorsResponse: CompetitorResponse = { data: { success: "false", message: "", competitor: [] } };
  public projects: Project[] = [];
  public rootStore: RootStore | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async getCompetitors() {
    this.fetchCompetitorsIsErroneous = false;
    this.cleanCompetitorState();

    try {
      const response = await MarketingApi.getCompetitors();
      if (response.data.success === "true") {
        this.competitorsResponse = response;
        this.projects = response.data.competitor;
        this.projects.push({ id: 19, name: "Unmarshal", industry: "Data feed" });
        return this.projects;
      }
      return;
    } catch {
      this.fetchCompetitorsIsErroneous = true;
      return;
    }
  }

  public cleanCompetitorState = () => {
    this.competitorsResponse = { data: { success: "false", message: "", competitor: [] } };
    this.projects = [];
  };
}

export default CompetitorStore;
