import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import MetaMask from "../metamask.svg";
import { useStores } from "src/store/RootStore";

const LoginForm = ({ onMetaMaskSubmit }) => {
  const { loginStore } = useStores();

  const handleMetaMaskLogin = () => {
    loginStore.handleLoginFormDisplay(false)
    onMetaMaskSubmit();
  };

  return (
    <>
      <div className="Login d-flex flex-column">
        <span className="Login--Title">Connect Wallet To Gain Ojamu Insights</span>
        <span className="Login--Subtitle">2000 OJA balance required in Web3 wallet to access.</span>
        <div className="Login__Container">
          <Button className="Button__Outlined Button__Outlined--MetaMask" onClick={(e) => handleMetaMaskLogin()}>
            <img className="Button__Outlined--logo" src={MetaMask} alt="MetaMask Log In" />
            <span>Log In With MetaMask</span>
          </Button>
        </div>

      </div>
    </>
  );
};

export default LoginForm;
