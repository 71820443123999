import axios from "axios";
import { stringify } from "querystring";
import { AxiosRequestConfig } from "axios";

export const BASE_URL = "https://marketing-api-prod.onrender.com/";

export const API_CONF: AxiosRequestConfig = {
  baseURL: BASE_URL,
  timeout: 15000,
  headers: {},
  paramsSerializer: (params) => stringify(params),
};

const API = axios.create(API_CONF);

export default API;
