import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import OJAToken from "../utils/OJAToken.json";

export const calculateMetaMaskCredentials = async () => {
  let account = "";
  const RINKEBY_OJAMU_TOKEN = "0xDC6d85a16ca63F06f6d6F474159Cd515aA03399B";
  const MAIN_NET_OJAMU_TOKEN = "0x0aa7efe4945db24d95ca6e117bba65ed326e291a";

  const getEnvContractToken = () => {
    console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      return RINKEBY_OJAMU_TOKEN;
    } else if (process.env.NODE_ENV === 'production') {
      return MAIN_NET_OJAMU_TOKEN;
    }
    return "";
  }

  const getContract = async () => {
    if (!window || !window.ethereum) {
      return null;
    }
    // @ts-ignore
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const tokenContract = getEnvContractToken();
    let wallet_provider = await detectEthereumProvider();
    //@ts-ignore
    await wallet_provider.enable();
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(wallet_provider);
    const signer = provider.getSigner();

    const accounts = await provider.listAccounts();
    account = accounts[0];

    return new ethers.Contract(
      tokenContract,
      OJAToken.abi,
      signer);
  }

  const getBalance = async () => {
    let ojaTokenContracts = await getContract();
    if (!ojaTokenContracts) {
      return 0;
    }

    try {
      let balance = await ojaTokenContracts.balanceOf(
        account,
        { from: account }
      );

      return Number(ethers.utils.formatEther(balance));
    } catch(error) {
      console.log(error);
    }
    return 0;
  }

  return {
    balance: await getBalance(),
    account: account
  };
};
