import React from "react";
import Navigation from "../components/Navigation";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div className="Wrapper">
        <Navigation />
        <main className="Main">{children}</main>
      </div>
    </React.Fragment>
  );
};
export default Layout;
