import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import MarketingApi from "../api/MarketingApi";

export const JWT_TOKEN = "token";

export interface LoginStoreInterface {
  loginIsSuccessful: boolean;
  metaMaskLoginIsErroneous: boolean;
  metaMaskIsLoading: boolean;
  showLoginForm: boolean;
}

class LoginStore implements LoginStoreInterface {
  public loginIsSuccessful: boolean = false;
  public metaMaskLoginIsErroneous: boolean = false;
  public metaMaskIsLoading: boolean = false;
  public rootStore: RootStore | undefined;
  public showLoginForm: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async loginWithMetaMask(credentials: MetaMaskCredentials) {
    this.metaMaskIsLoading = true;
    this.metaMaskLoginIsErroneous = false;
    this.loginIsSuccessful = false;

    try {
      const response = await MarketingApi.logInWithMetaMaskByBalance(credentials);
      if (response.data.success === "true") {
        sessionStorage.setItem(JWT_TOKEN, response.data.token);
        this.loginIsSuccessful = true;
      }
    } catch {
      this.metaMaskLoginIsErroneous = true;
    } finally {
      this.metaMaskIsLoading = false;
    }
  }

  public handleLoginFormDisplay(visible: boolean) {
    return (this.showLoginForm = visible);
  }

  public isAuthenticated() {
    const token = sessionStorage.getItem(JWT_TOKEN);
    if (token === null) return false;
    return token.length > 1;
  }

  public logOut() {
    sessionStorage.removeItem(JWT_TOKEN);
    this.loginIsSuccessful = false;
  }
}

export default LoginStore;
