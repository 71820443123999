export const priceTwitterEngagementResponseMock: PriceTwitterEngagementResponse = {
  data: {
    success: "true",
    message: "PriceXEngagementRate graph",
    graph: {
      label: "Cardano",
      data: [
        {
          date: "2022-01-10T00:00:00.000Z",
          twitter_engagement: "394",
          price: 1.1546551,
        },
        {
          date: "2022-01-11T00:00:00.000Z",
          twitter_engagement: "1314",
          price: 1.14342,
        },
        {
          date: "2022-01-12T00:00:00.000Z",
          twitter_engagement: "270",
          price: 1.25,
        },
        {
          date: "2022-01-13T00:00:00.000Z",
          twitter_engagement: "291",
          price: 1.2911148,
        },
        {
          date: "2022-01-14T00:00:00.000Z",
          twitter_engagement: "1395",
          price: 1.24142414,
        },
        {
          date: "2022-01-15T00:00:00.000Z",
          twitter_engagement: "147",
          price: 1.26780246,
        },
        {
          date: "2022-01-16T00:00:00.000Z",
          twitter_engagement: "70",
          price: 1.3835715,
        },
      ],
    },
  },
};

export const priceXFollowersResponseMock: PriceXFollowersResponse = {
  data: {
    success: "true",
    message: "PriceXFollowers graph",
    graph: {
      label: "Cardano",
      data: [
        {
          date: "2022-01-10T00:00:00.000Z",
          twitter_followers_count: "394",
          price: 1.1546551,
        },
        {
          date: "2022-01-11T00:00:00.000Z",
          twitter_followers_count: "1314",
          price: 1.14342,
        },
        {
          date: "2022-01-12T00:00:00.000Z",
          twitter_followers_count: "270",
          price: 1.25,
        },
        {
          date: "2022-01-13T00:00:00.000Z",
          twitter_followers_count: "291",
          price: 1.2911148,
        },
        {
          date: "2022-01-14T00:00:00.000Z",
          twitter_followers_count: "1395",
          price: 1.24142414,
        },
        {
          date: "2022-01-15T00:00:00.000Z",
          twitter_followers_count: "147",
          price: 1.26780246,
        },
        {
          date: "2022-01-16T00:00:00.000Z",
          twitter_followers_count: "70",
          price: 1.3835715,
        },
      ],
    },
  },
};

export const graphDataMock: GraphData =
  {
    competitorId: 1,
    competitorLabel: "Cardano",
    dataPoints: [
      {
        date: "2022-01-10",
        leftYAxis: 1.1546551,
        rightYAxis: 394,
      },
      {
        date: "2022-01-11",
        leftYAxis: 1.14342,
        rightYAxis: 1314,
      },
      {
        date: "2022-01-12",
        leftYAxis: 1.25,
        rightYAxis: 270,
      },
      {
        date: "2022-01-13",
        leftYAxis: 1.2911148,
        rightYAxis: 291,
      },
      {
        date: "2022-01-14",
        leftYAxis: 1.24142414,
        rightYAxis: 1395,
      },
      {
        date: "2022-01-15",
        leftYAxis: 1.26780246,
        rightYAxis: 147,
      },
      {
        date: "2022-01-16",
        leftYAxis: 1.3835715,
        rightYAxis: 70,
      }
    ]
  };

export const priceTwitterEngagementRequestMock: PriceTwitterEngagementRequest = {
  competitorId: 1,
  startDate: "2022-01-10",
  endDate: "2022-01-17",
};

export const priceXFollowersRequestMock: PriceXFollowersRequest = {
  competitorId: 1,
  startDate: "2022-01-11",
  endDate: "2022-01-18",
};

export const priceXTokenHoldersRequestMock: PriceXTokenHoldersRequest = {
  competitorId: 1,
  startDate: "2022-01-11",
  endDate: "2022-01-19",
};

export const competitorResponseMock: CompetitorResponse = {
  data: {
    success: "true",
    message: "OK",
    competitor: [
      {
        id: 6,
        name: "Binance Smart Chain",
        industry: "Layer 1"
      },
      {
        id: 13,
        name: "Bitcoin",
        industry: "Layer 1"
      },
      {
        id: 7,
        name: "Cardano",
        industry: "Layer 1"
      },
      {
        id: 10,
        name: "Chainlink",
        industry: "DeFi"
      },
      {
        id: 11,
        name: "Decentraland",
        industry: "Metaverse"
      },
      {
        id: 17,
        name: "Dogecoin",
        industry: "Meme"
      },
      {
        id: 15,
        name: "Enjin",
        industry: "Gaming"
      },
      {
        id: 2,
        name: "Ethereum",
        industry: "Layer 1"
      },
      {
        id: 9,
        name: "Fantom",
        industry: "Scaling"
      },
      {
        id: 18,
        name: "Ojamu",
        industry: "Ojamu"
      },
      {
        id: 3,
        name: "Polkadot",
        industry: "Layer 1"
      },
      {
        id: 8,
        name: "Polygon",
        industry: "Scaling"
      },
      {
        id: 12,
        name: "Shib",
        industry: "Metaverse"
      },
      {
        id: 1,
        name: "Solana",
        industry: "Layer 1"
      },
      {
        id: 16,
        name: "The Sandbox",
        industry: "Metaverse"
      },
    ],
  },
};

export const projectMock: Project[] = [
  {
    id: 6,
    name: "Binance Smart Chain",
    industry: "Layer 1"
  },
  {
    id: 13,
    name: "Bitcoin",
    industry: "Layer 1"
  },
  {
    id: 7,
    name: "Cardano",
    industry: "Layer 1"
  },
  {
    id: 10,
    name: "Chainlink",
    industry: "DeFi"
  },
  {
    id: 11,
    name: "Decentraland",
    industry: "Metaverse"
  },
  {
    id: 17,
    name: "Dogecoin",
    industry: "Meme"
  },
  {
    id: 15,
    name: "Enjin",
    industry: "Gaming"
  },
  {
    id: 2,
    name: "Ethereum",
    industry: "Layer 1"
  },
  {
    id: 9,
    name: "Fantom",
    industry: "Scaling"
  },
  {
    id: 18,
    name: "Ojamu",
    industry: "Ojamu"
  },
  {
    id: 3,
    name: "Polkadot",
    industry: "Layer 1"
  },
  {
    id: 8,
    name: "Polygon",
    industry: "Scaling"
  },
  {
    id: 12,
    name: "Shib",
    industry: "Metaverse"
  },
  {
    id: 1,
    name: "Solana",
    industry: "Layer 1"
  },
  {
    id: 16,
    name: "The Sandbox",
    industry: "Metaverse"
  },
];
