import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";
import { formattedDate } from "../utils/DateFormatter";

export interface GraphStoreInterface {
  priceTwitterEngagementByRangeIsErroneous: boolean;
  priceTwitterEngagementData: PriceTwitterEngagementData[];
  priceXFollowersData: PriceXFollowersData[];
  graphData: GraphData | undefined;
}

export const GraphTypeEngagement = "Engagement";
export const GraphTypeFollowers = "Followers";
export const GraphTypeTokenHolders = "TokenHolders";

class GraphStore implements GraphStoreInterface {
  public priceTwitterEngagementByRangeIsErroneous: boolean = false;
  public priceTwitterEngagementData: PriceTwitterEngagementData[] = [];
  public priceXFollowersData: PriceXFollowersData[] = [];
  public priceXTokenHoldersData: PriceXTokenHoldersData[] = [];
  public graphData: GraphData | undefined = undefined;
  public rootStore: RootStore | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async getGraphDataByRange(range: number, competitorId: number, graphType: string) {
    this.priceTwitterEngagementByRangeIsErroneous = false;
    this.cleanPriceTwitterEngagementState();

    const timeFrame = this.getDateRange(range);

    if (graphType === GraphTypeEngagement) {
      const request: PriceTwitterEngagementRequest = {
        competitorId: competitorId,
        startDate: timeFrame.startDate,
        endDate: timeFrame.endDate,
      };

      try {
        const response = await MarketingApi.getPriceTwitterEngagementMetrics(request);

        if (response.data.success === "true") {
          this.priceTwitterEngagementData = response.data.graph.data;
          this.priceTwitterEngagementData.forEach((data, index) => {
            if (data.price === null)
              this.priceTwitterEngagementData[index].price = 0;
          });

          const label = response.data.graph.label;
          this.createPriceXEngagementGraphData(label, competitorId);
          return this.graphData;
        }
        return;
      } catch {
        this.priceTwitterEngagementByRangeIsErroneous = true;
        return;
      }
    } else if (graphType === GraphTypeFollowers) {
      const request: PriceXFollowersRequest = {
        competitorId: competitorId,
        startDate: timeFrame.startDate,
        endDate: timeFrame.endDate,
      };

      try {
        const response = await MarketingApi.getPriceXFollowers(request);
        if (response.data.success === "true") {
          this.priceXFollowersData = response.data.graph.data;
          this.priceXFollowersData.forEach((data, index) => {
            if (data.price === null) this.priceXFollowersData[index].price = 0;
          });
          const label = response.data.graph.label;
          this.createPriceXFollowersGraphData(label, competitorId);
          return this.graphData;
        }
        return;
      } catch {
        this.priceTwitterEngagementByRangeIsErroneous = true;
        return;
      }
    } else if (graphType === GraphTypeTokenHolders) {
      const request: PriceXTokenHoldersRequest = {
        competitorId: competitorId,
        startDate: timeFrame.startDate,
        endDate: timeFrame.endDate,
      };

      try {
        const response = await MarketingApi.getPriceXTokenHolders(request);
        if (response.data.success === "true") {
          this.priceXTokenHoldersData = response.data.graph.data;
          this.priceXTokenHoldersData.forEach((data, index) => {
            if (data.price === null)
              this.priceXTokenHoldersData[index].price = 0;
          });
          const label = response.data.graph.label;
          this.createPriceXTokenHoldersGraphData(label, competitorId);
          return this.graphData;
        }
        return;
      } catch {
        this.priceTwitterEngagementByRangeIsErroneous = true;
        return;
      }
    }
    return;
  }

  public createPriceXEngagementGraphData(label: string, competitorId: number) {
    let graphDataPoints: GraphDataPoint[] = [];
    this.priceTwitterEngagementData.map((responseInstance) => {
      const competitorInDepthGraphDataPoint = {
        date: this.normalizeDate(responseInstance.date),
        rightYAxis: Number(responseInstance.twitter_engagement),
        leftYAxis: responseInstance.price,
      };
      graphDataPoints.push(competitorInDepthGraphDataPoint);
    });
    this.graphData = {
      competitorId: competitorId,
      competitorLabel: label,
      dataPoints: graphDataPoints,
    };
  }

  public createPriceXFollowersGraphData(label: string, competitorId: number) {
    let graphDataPoints: GraphDataPoint[] = [];
    this.priceXFollowersData.map((responseInstance) => {
      const competitorInDepthGraphDataPoint = {
        date: this.normalizeDate(responseInstance.date),
        rightYAxis: Number(responseInstance.twitter_followers_count),
        leftYAxis: responseInstance.price,
      };
      graphDataPoints.push(competitorInDepthGraphDataPoint);
    });

    this.graphData = {
      competitorId: competitorId,
      competitorLabel: label,
      dataPoints: graphDataPoints,
    };
  }

  public createPriceXTokenHoldersGraphData(label: string, competitorId: number) {
    let graphDataPoints: GraphDataPoint[] = [];
    this.priceXTokenHoldersData.map((responseInstance) => {
      const competitorInDepthGraphDataPoint = {
        date: this.normalizeDate(responseInstance.date),
        rightYAxis: Number(responseInstance.token_holder_count),
        leftYAxis: responseInstance.price,
      };
      graphDataPoints.push(competitorInDepthGraphDataPoint);
    });

    this.graphData = {
      competitorId: competitorId,
      competitorLabel: label,
      dataPoints: graphDataPoints,
    };
  }

  private normalizeDate(rawDate: string) {
    return rawDate.slice(0, 10);
  }

  private cleanPriceTwitterEngagementState() {
    this.graphData = undefined;
  }

  private getDateRange(dateRange: number) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const past = new Date();
    const pastRange = dateRange + 1;
    past.setDate(past.getDate() - pastRange);

    return {
      startDate: formattedDate(past, "ymd"),
      endDate: formattedDate(yesterday, "ymd"),
    };
  }
}

export default GraphStore;
