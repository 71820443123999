import discord from "./discord-icon.svg";
import twitter from "./twitter-icon.svg";
import telegram from "./telegram-icon.svg";

const CryptoIcon = (props: any) => {
  const iconMap = {
    twitter,
    telegram,
    discord
  };
  return <img className="SocialmediaIcon" src={iconMap[props.id]} alt={props.name} />;
};

export default CryptoIcon;
