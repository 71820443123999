import eth from "./png/eth-icon.png";
import fantom from "./png/fantom-icon.png";
import solana from "./png/solana-icon.png";
import cardano from "./png/cardano-icon.png";
import chainlink from "./png/chainlink-icon.png";
import decentral from "./png/decentral-icon.png";
import binance from "./png/binance-icon.png";
import polkadot from "./png/polkadot-icon.png";
import polygon from "./png/polygon-icon.png";
import shib from "./png/shiba-icon.png";
import btc from "./png/btc-icon.png";
import eng from "./png/eng-icon.png";
import sandbox from "./png/sandbox-icon.png";
import dogecoin from "./png/doge-icon.png";
import oja from "./png/oja-icon.png";
import unmarshal from "./unmarshal.svg";

const CryptoIcon = (props: any) => {
  const iconMap = {
    1: solana,
    2: eth,
    3: polkadot,
    6: binance,
    7: cardano,
    8: polygon,
    9: fantom,
    10: chainlink,
    11: decentral,
    12: shib,
    13: btc,
    15: eng,
    16: sandbox,
    17: dogecoin,
    18: oja,
    19: unmarshal,
  };

  return <img className="CryptoIcon" src={iconMap[props.id]} alt={props.name} width="22px" height="22px" />;
};

export default CryptoIcon;
