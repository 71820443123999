import React, { useContext, useEffect } from "react";
import GraphContainer from "../components/Graph/GraphContainer";
import { OjamuContext } from "../index.js";
import DashboardLayout from "../layouts/DashboardLayout";

const ContentPage = (props) => {
  const { value, setValue } = useContext(OjamuContext);

  useEffect(() => {
    setValue(1);
  }, [setValue]);

  return (
    <DashboardLayout>
      <div className="container container--stepped">
        <div className="row">
          <div className="col col-12">
            <>
              {value !== "0" ? (
                <GraphContainer companyID={value} graphType={props.graphType} />
                ) : (
                  <h6 className="p-5 text-center">Please select a company</h6>
                  )}
            </>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ContentPage;
