import React, { createContext, useState } from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ContentPage } from "./pages";
import "./styles/App.scss";
import { GraphTypeEngagement, GraphTypeFollowers, GraphTypeTokenHolders } from "./store/GraphStore";
import { useStores } from "./store/RootStore";


export const OjamuContext = createContext({
  value: 0,
  setValue: () => {},
});
const rootElement = document.getElementById("root");

function PrivateOutlet() {
  const { loginStore } = useStores();
  return loginStore.isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
}

function PublicOutlet() {
  const targetPage = "/engagement";
  const { loginStore } = useStores();
  return loginStore.isAuthenticated() ? <Navigate to={targetPage} /> : <Outlet />;
}

const App = () => {
  const [value, setValue] = useState("0");
  const curr = { value, setValue };

  //TODO: 49: Change to PrivateOutlet

  return (
    <React.StrictMode>
      <OjamuContext.Provider value={curr}>
        <BrowserRouter>
          <Routes>

            <Route path="/" element={<Navigate to={"/engagement"} />}>
            </Route>

            <Route path="/engagement" element={<ContentPage graphType={GraphTypeEngagement}/>}>
            </Route>

            <Route path="/followers" element={<ContentPage graphType={GraphTypeFollowers}/>}>
            </Route>

            <Route path="/holders" element={<ContentPage graphType={GraphTypeTokenHolders}/>}>
            </Route>

          </Routes>
        </BrowserRouter>
      </OjamuContext.Provider>
    </React.StrictMode>
  );
};

render(<App />, rootElement);
